import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import logo from "../../assets/mmpsrpc.svg";
import { FaLinkedinIn } from "react-icons/fa";
import axios from 'axios'; // Make sure to install axios if you haven't already

const Footer = () => {
  
  const quickLinks = [
    { name: "Home", route: "/" },
    { name: "About Us", route: "/aboutus" },
    { name: "Hackathons", route: "/hackathons" },
    { name: "Projects", route: "/projects" },
    { name: "Events", route: "/events" },
    { name: "Coordinators", route: "/faculties" },
    { name: "Achievements", route: "/achievements" },
    { name: "Gallery", route: "/gallery" },
    { name: "Contact Us", route: "/contactus" },
    { name: "News", route: "/news" },
  ];

  const socialLinks = [
    { Icon: FaLinkedinIn, url: "https://www.linkedin.com/company/mmpsrpc", label: "LinkedIn", username: "mmpsrpc" },
  ];

  return (
    <footer className="bg-teal-800 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
          <div className="col-span-1 md:col-span-2 lg:col-span-1 flex flex-col items-center">
            <img
              src={logo}
              alt="M. M. Patel Students Research Project Cell Logo"
              className="h-32 w-32 mb-4 object-contain"
            />
            <p className="text-sm text-center max-w-xs">
              Inspiring students to innovate, collaborate, and make significant societal contributions through research excellence, since 2024
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 text-green-300">Quick Links</h3>
            <div className="grid grid-cols-2 gap-x-4">
              {quickLinks.map((item, index) => (
                <div key={index} className="mb-2">
                  <a
                    href={item.route}
                    className="text-sm hover:text-green-300 transition duration-300 block"
                  >
                    {item.name}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 text-green-300">Contact Us</h3>
            <address className="text-sm not-italic space-y-2">
              <p>M. M. Patel Students Research Project Cell</p>
              <p>KSV University, Gandhinagar, Gujarat, India</p>
              <p>Phone: 079-232-44690</p>
              <p>Email: mmpsrc.ksv@gmail.com</p>
            </address>
          </div>
          <div className="col-span-1 md:col-span-2 lg:col-span-1">
            <h3 className="text-lg font-semibold mb-4 text-green-300">Follow Us</h3>
            <div className="flex flex-col space-y-4">
              {socialLinks.map(({ Icon, url, label, username }, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <motion.a
                    href={url}
                    aria-label={label}
                    className="text-2xl hover:text-green-300 transition duration-300"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    target='_blank'
                    rel="noopener noreferrer"
                  >
                    <Icon />
                  </motion.a>
                  <a href={url} target='_blank' rel='noopener noreferrer'>
                    <span className="text-md font-bold">@{username}</span>
                  </a> 
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-12 pt-8 border-t border-teal-700 text-center text-sm">
          <p>
            © {new Date().getFullYear()} M. M. Patel Students Research
            Project Cell. All rights reserved.
          </p>
        
        </div>
      </div>
    </footer>
  );
};

export default Footer;