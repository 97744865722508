import React, { useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import { motion, useScroll, useSpring, AnimatePresence } from "framer-motion";
import { FaChevronUp } from "react-icons/fa";
import "./App.css";
import "./index.css"
import Header from "./components/Atoms/Header";
import Footer from "./components/Atoms/Footer";
import logo from "./assets/mmpsrpc.svg"

// Lazy load components
const HomePage = lazy(() => import("./pages/Homepage"));
const AboutUs = lazy(() => import("./pages/AboutPage"));
const HackathonListingPage = lazy(() => import("./pages/HackathonsPage"));
const Projects = lazy(() => import("./pages/ProjectsPage"));
const Achievements = lazy(() => import("./pages/AchievementPage"));
const Faculties = lazy(() => import("./pages/FacultiesPage"));
const ContactForm = lazy(() => import("./pages/ContactUsPage"));
const Events = lazy(() => import("./pages/EventsPage"));
const HackathonProfile = lazy(() => import("./pages/ViewHackathonPage"));
const EventProfile = lazy(() => import("./pages/ViewEventPage"));
const ProjectProfile = lazy(() => import("./pages/ViewProject"));
const News = lazy(() => import("./pages/NewsPage"));
const Mission = lazy(() => import("./components/About/AboutMission"));
const Vision = lazy(() => import("./components/About/AboutVision"));
const Goals = lazy(() => import("./components/About/AboutGoals"));
const Gallery = lazy(() => import("./pages/GalleryPage"));
const KSV = lazy(() => import("./components/About/AboutKSV"));
const SVKM = lazy(() => import("./components/About/AboutSVKM"));
const IEEE = lazy(() => import("./components/About/AboutIEEE"));
// Loader component 
const Loader = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-teal-600">
    <div className="relative">
      <img src={logo} alt="MMPSRPC Logo" className="w-32 h-32 animate-pulse" />
      <div className="absolute inset-0 animate-ping">
        <div className="absolute inset-0 bg-yellow-300 opacity-75 rounded-full scale-0 animate-ripple"></div>
        <div className="absolute inset-0 bg-yellow-300 opacity-75 rounded-full scale-0 animate-ripple animation-delay-300"></div>
        <div className="absolute inset-0 bg-yellow-300 opacity-75 rounded-full scale-0 animate-ripple animation-delay-600"></div>
      </div>
    </div>
  </div>
);
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [pathname]);

  return null;
}

function App() {
  const [isVisible, setIsVisible] = useState(false);
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <Router>
      <ScrollToTop />
      <AnimatePresence>
        <Header />
        <motion.div
          className="fixed top-0 left-0 right-0 h-1 bg-yellow-50 origin-left z-50"
          style={{ scaleX }}
        />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/hackathons" element={<HackathonListingPage />} />
            <Route path="/hackathon/:id" element={<HackathonProfile />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/:id" element={<ProjectProfile />} />
            <Route path="/achievements" element={<Achievements />} />
            <Route path="/faculties" element={<Faculties />} />
            <Route path="/contactus" element={<ContactForm />} />
            <Route path="/events" element={<Events />} />
            <Route path="/event/:id" element={<EventProfile />} />
            <Route path="/news" element={<News />} />
            <Route path="/about/mission" element={<Mission />} />
            <Route path="/about/vision" element={<Vision />} />
            <Route path="/about/goals" element={<Goals />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/ksv" element={<KSV />} />
            <Route path="/svkm" element={<SVKM />} />
            <Route path="/ieee" element={<IEEE />} />
          </Routes>
        </Suspense>
        <Footer />
        <AnimatePresence>
          {isVisible && (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              transition={{ duration: 0.5 }}
              className="fixed bottom-8 right-8 z-50"
            >
              <div className="relative">
                <svg className="w-16 h-16 -rotate-90">
                  <circle
                    cx="32"
                    cy="32"
                    r="30"
                    stroke="#E2E8F0"
                    strokeWidth="4"
                    fill="none"
                  />
                  <motion.circle
                    cx="32"
                    cy="32"
                    r="30"
                    stroke="#38B2AC"
                    strokeWidth="4"
                    fill="none"
                    strokeDasharray="0 1"
                    style={{
                      pathLength: scrollYProgress
                    }}
                  />
                </svg>
                <motion.button
                  onClick={scrollToTop}
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-teal-600 text-white p-3 rounded-full shadow-lg hover:bg-teal-700 transition duration-300 border-2 border-white"
                >
                  <FaChevronUp className="text-xl" />
                </motion.button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </AnimatePresence>
    </Router>
  );
}
export default App;